.fill-container-width {
    width: 100%;
}

.login-button {
    width: 33%;
}

.login-card {
    width: 50%;
}

.login-container {
    padding: 10%;
}

.outer-container {
    padding-top: 5%;
}

.login-inner-container {
    padding: 12%;
}

.login-outer-container {
    padding-top: 3%;
}

.vertical-spacing {
    padding-top: 5%;
}