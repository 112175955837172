.outer {
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 800px;
}

.fill-parent {
    width: 100%;
    height: 100%;
}

div.outer {
    aspect-ratio: 1 / 1;
}

.test-div {
    border: 1px solid black;
}
