.site-nav-link {
    font-size: x-large;
}

.navbar-brand {
    padding-left: 20%;
}

nav {
    padding-right: 5%;
}