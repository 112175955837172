.accordion-outer-container {
    width: 60%;
}

.accordion-btn {
    width: 25%;
}

.accordion-body {
    padding: 5%;
}