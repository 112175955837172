.error-message {
    color: red;
}

.modal-container {
    padding: 5%;
}

.modal-button {
    width: 100%;
}

.modal-button-row {
    padding-top: 5%;
}

.modal-login-register-container {
    padding: 10%;
}

.part-info-dropdown {
    color: black;
    background-color: gainsboro;
}
  
#rendering {
    height: 400px;
}

#submit-button {
    width: 25%
}
  
#vs-iframe {
    border:0;
    margin:0;
    width:100%;
    height:100%;
}
  
